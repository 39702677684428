/* ---------------------
General Rules 
-----------------------*/
.main-content {
  padding-top: 58px; /* Adjust this value based on the actual height of your navbar */
}

/* ----------
Navbar
---------- */
.navbar-nav .active-dropdown a {
  color: rgba(0, 0, 0, 1);
}

/* ----------
Masthead
---------- */
.masthead_headertext {
  color: white;
  font-size: 2.75rem;
  font-weight: 500;
}

.masthead_headersubtext {
  color: white;
  font-size: 0.75rem;
  font-weight: 500;
}

/* ---------------------
Messages 
-----------------------*/
.reply-indicator {
  color: grey;
  font-weight: bold;
}

/* ---------------------
Gallery Thumbnails
-----------------------*/
.carousel-item .carouselGallery-caption {
  position: absolute;
  bottom: -1rem;
  background: rgba(36, 36, 36, 0.75);
  color: #fff;
  font-size: 1.25rem;
  left: 0px;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  right: 0px;
  text-align: center;
}

/* ----------
Footer
---------- */
.footer {
  background-color: #ececf1;
  border-top: solid 2px #9e9e9e;
}

.footer-col {
  display: flex;
  align-items: center;
  justify-content: center; /* or 'flex-start' for left alignment, 'flex-end' for right */
}

.socialMedia svg:hover {
  transform: scale(1.4);
  cursor: pointer;
}
.socialMedia svg {
  transition: all 1s;
  transform-origin: 50% 50%;
}
.socialMedia {
  cursor: pointer;
}

/* ---------------------
Parts Network Page
---------------------- */

/* inventory manager and stats manager images */
.IMGrid {
  transition: box-shadow 0.3s ease;
}

.IMGrid:hover {
  cursor: pointer;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.35);
}

.pn-modal .modal-image-container {
  position: relative;
}

.pn-modal .modal-body {
  display: flex;
  align-items: center;
  padding: 0;
  text-align: center;
}

.pn-carousel .carousel-control-prev,
.pn-carousel .carousel-control-next {
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
  width: auto;
}

.pn-carousel .carousel-control-prev {
  left: -70px;
}

.pn-carousel .carousel-control-next {
  right: -70px;
}

/* quick quote images */
.quickquote_image {
  height: 95%;
  width: 95%;
}

/* -----------
For Sale Page
-------------- */

.ForSaleth {
  border-right: 1px solid #ccc; /* Adjust border color and thickness as needed */
  border-top: 1px solid #ccc; /* Adjust border color and thickness as needed */
  border-left: 1px solid #ccc; /* Adjust border color and thickness as needed */
  border-bottom: 2px solid black !important; /* Adjust thickness and color for the bottom border */
}
.attachment-companyname {
  max-width: 10rem;
}

.sortable-header {
  cursor: pointer;
  color: #007bff !important; /* Bootstrap's default link color */
  text-decoration: underline;
  user-select: none; /* Prevent text selection */
}

.clickable-column {
  cursor: pointer;
  color: #4986c7 !important;
  font-weight: 700;
  text-decoration: underline;
  /* user-select: none; */
}

.sortable-header:hover {
  color: #0056b3; /* Darker blue on hover for better interaction feedback */
}

/* -----------
Storefront Page
------------- */
.marketplace-image {
  transition: box-shadow 0.3s ease;
}

.marketplace-image:hover {
  cursor: pointer;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.35);
}

.marketplace-carousel .carousel-control-prev,
.marketplace-carousel .carousel-control-next {
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
  width: auto;
}

.marketplace-carousel .carousel-control-prev {
  left: -70px;
}

.marketplace-carousel .carousel-control-next {
  right: -70px;
}

.marketplace-modal {
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* ---------------
Testimonials Page 
--------------- */
.testimonials_image {
  height: auto;
  max-width: 67%;
}

@media (max-width: 576px) {
  .hide-on-small-screen {
    display: none;
  }
}

/* ####################################################### 576px */
@media (min-width: 576px) {
  /* -------------------------
  Masthead
  ------------------------- */
  .masthead_headertext {
    font-size: 3.25rem;
  }

  .masthead_headersubtext {
    font-size: 0.9rem;
  }

  /* ---------------------
  Gallery Thumbnails
  -----------------------*/
  .carouselGallery-caption {
    position: absolute;
    bottom: -0.75rem;
    font-size: 2rem;
  }
}

/* ####################################################### 768px */
@media (min-width: 768px) {
  /* -------------------------
  Masthead
  ------------------------- */
  .masthead_headertext {
    font-size: 4rem;
  }

  .masthead_headersubtext {
    font-size: 1.05rem;
  }

  /* ---------------------
  Gallery Thumbnails
  -----------------------*/
  .carouselGallery-caption {
    position: absolute;
    bottom: -0.5rem;
    font-size: 2.5rem;
  }

  /* ---------------
  Parts Network Page 
  --------------- */
  .quickquote_image {
    height: 75%;
    width: 75%;
  }

  /* ---------------
  Testimonials Page 
  --------------- */
  .testimonials_image {
    max-width: 40%;
  }
}

/* ####################################################### 992px */
@media (min-width: 992px) {
  /* -------------------------
  Masthead
  ------------------------- */
  .masthead_headertext {
    font-size: 5rem;
  }

  .masthead_headersubtext {
    font-size: 1.2rem;
  }

  /* ---------------------
  Gallery Thumbnails
  -----------------------*/
  .carouselGallery-caption {
    position: absolute;
    bottom: -1rem;
    font-size: 1.5rem;
  }

  /* ---------------
  Testimonials Page 
  --------------- */
  .testimonials_image {
    max-width: 60%;
  }
}

/* ####################################################### 1200px */
@media (min-width: 1200px) {
  /* -------------------------
  Masthead
  ------------------------- */
  .masthead_headertext {
    font-size: 6rem;
  }

  .masthead_headersubtext {
    font-size: 1.4rem;
  }

  /* ---------------------
  Gallery Thumbnails
  -----------------------*/
  .carouselGallery-caption {
    position: absolute;
    bottom: -0.75rem;
    font-size: 1.75rem;
  }

  /* ---------------
  Testimonials Page 
  --------------- */
  .testimonials_image {
    max-width: 50%;
  }
}

/* ####################################################### 1400px */
@media (min-width: 1400px) {
  /* -------------------------
  Masthead
  ------------------------- */
  .masthead_headertext {
    font-size: 7rem;
  }

  .masthead_headersubtext {
    font-size: 1.65rem;
  }

  /* ---------------------
  Gallery Thumbnails
  -----------------------*/
  .carouselGallery-caption {
    position: absolute;
    bottom: -0.5rem;
    font-size: 2rem;
  }
}
